import { SumamoruProjectType } from './sumamoru-project-type';
import { ServicerType } from './servicer-type';

export type ProjectServicerType =
  | 'CDE'
  | 'OSSinCDE'
  | 'BOinCDE'
  | 'Keiyou'
  | 'OSSinKeiyou'
  | 'BOinKeiyou'
  | 'Saibugas'
  | 'OSSinSaibugas'
  | 'BOinSaibugas';

export const getProjectAndServicerFromId = (id: string): { project: SumamoruProjectType; servicer: ServicerType } => {
  switch (id) {
    case ProjectServicerInternalEnum.CdeCde:
      return {
        project: SumamoruProjectType.CDE,
        servicer: ServicerType.CDE
      };
    case ProjectServicerInternalEnum.CdeOss:
      return {
        project: SumamoruProjectType.CDE,
        servicer: ServicerType.OSS
      };
    case ProjectServicerInternalEnum.KeiyouKeiyou:
      return {
        project: SumamoruProjectType.KeiyouGas,
        servicer: ServicerType.KeiyouGas
      };
    case ProjectServicerInternalEnum.KeiyouOss:
      return {
        project: SumamoruProjectType.KeiyouGas,
        servicer: ServicerType.OSS
      };
    case ProjectServicerInternalEnum.SaibugasSaibu:
      return {
        project: SumamoruProjectType.SaibuGas,
        servicer: ServicerType.SaibuGas
      };
    case ProjectServicerInternalEnum.SaibugasOss:
      return {
        project: SumamoruProjectType.SaibuGas,
        servicer: ServicerType.OSS
      };
  }
  throw new Error(`There is not found, id: ${id}`);
};

export const getProjectAndServicerIdFromType = (project: SumamoruProjectType, servicer: ServicerType): string => {
  const id = Object.values(ProjectServicerInternalEnum).find(
    id => getProjectAndServicerFromId(id).project === project && getProjectAndServicerFromId(id).servicer === servicer
  );
  if (!id) {
    throw new Error(`Not find project servicer, project: ${project}, servicer: ${servicer}`);
  }
  return id;
};

enum ProjectServicerInternalEnum {
  CdeCde = '816b09e3-bafa-4e10-a71b-0953c0d65ee4',
  CdeOss = '37f985d0-14e8-420b-89ba-3dd68e31613c',
  KeiyouKeiyou = '911a477e-8e20-4339-b131-fa957051b920',
  KeiyouOss = 'eacdc7e7-b3d5-4c74-8db5-ed565c44e885',
  SaibugasSaibu = '095d724a-c845-4b24-91de-5ade4b6d0bb5',
  SaibugasOss = '2e10a590-6cca-4cf3-bc34-a78b6788077c'
}
