import { ServicerType } from '../enums/common/servicer-type';
import { SumamoruProjectType } from '../enums/common/sumamoru-project-type';
import { AlgoliaConfigType, AlgoliaConfigTableUnit } from './algoliaConfigType';

export const region = 'ap-northeast-1';
export const stage = 'develop';
export const bkpPath = 'https://edge.bitkey.tokyo/v1';
export const ifBasePath = 'https://develop.api.cde.og.bitlock.xyz/link/api/v1/';
export const bmResidence =
  'https://us-central1-bitlock-dev.cloudfunctions.net/ext-vo/';

const AlgoliaAllSpaceAccount: AlgoliaConfigTableUnit = {
  indexName: 'dev_allSpaces',
  applicationId: '7TOIJPY60X',
  searchOnlyApiKey: 'c4d23d0ad1748f712ce53fedeff4b735',
  adminApiKey: 'c4d23d0ad1748f712ce53fedeff4b735',
  monitoringApiKey: 'c4d23d0ad1748f712ce53fedeff4b735'
};

export const getProjectId = (type: SumamoruProjectType): string => {
  switch (type) {
    case 'CDE':
      return '48566ff5-3e7c-4c1f-ac2f-73cef2009f64';
    case 'KeiyouGas':
      return '7a36af26-34c3-4ef7-9920-f876cde2bc44';
    case 'SaibuGas':
      return '8d3920e2-cb26-48f5-bb08-a09ed4463241';
  }
  throw new Error(`there is no type: ${type}`);
};

export const getProjectType = (id: string): SumamoruProjectType => {
  const key = Object.values(SumamoruProjectType).find(key => {
    try {
      return getProjectId(key) == id;
    } catch (e) {
      return false;
    }
  });
  if (!key) {
    throw new Error(`there is no id: ${id}`);
  }
  return key;
};

export const AlgoliaConfig: AlgoliaConfigType = {
  AllSpace: AlgoliaAllSpaceAccount
};

export const defaultProjectId = getProjectId(SumamoruProjectType.SaibuGas);
export const sumamoruProjectTypes = [SumamoruProjectType.SaibuGas];
export const energyServicerType = ServicerType.SaibuGas;