import React, { useCallback } from 'react';
import {
  Modal,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@material-ui/core';
import { Button } from '../atoms/ButtonWithAuth';
import { IconButton } from '../atoms/IconButtonWithAuth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close, GetApp, Warning } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import req from 'request-promise';
import { ifBasePath, defaultProjectId, getProjectType } from '../../config/baseConfig';
import Redux from '../../redux/ReduxConnector';
import LocalStorage, { LocalStorageKey } from '../../utils/LocalStorage';
import jaLocale from 'date-fns/locale/ja';
import KeyboardDatePicker from '../atoms/DatePickerWithAuth';
import { ServicerType } from '../../enums/common/servicer-type';
import { getProjectAndServicerIdFromType } from '../../enums/common/ProjectServicerType';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      background: 'var(--color-white)',
      borderRadius: 10,
      textAlign: 'left',
      width: 460,
      position: 'relative'
    },
    closeButton: {
      color: 'var(--color-white)',
      position: 'absolute',
      fontSize: 20,
      fontWeight: 900,
      top: 10,
      right: 16
    },
    heading: {
      borderRadius: '10px 10px 0 0',
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-admin-key)',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '29px',
      letterSpacing: '0.5px',
      padding: '20px'
    },
    submitButton: {
      backgroundColor: '#EB5757',
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-white)',
      '&:hover': {
        backgroundColor: '#ce4040'
      }
    },
    root: {
      width: 'calc(100% - 40px)',
      padding: '0 20px 20px 20px'
    },
    listItem: {
      lineHeight: '44px'
    },
    targetPeriodContainer: {
      background: 'var(--color-white)',
      textAlign: 'left'
    },
    targetPeriodLabel: {
      fontSize: 12,
      color: 'var(--color-gray-3)',
      fontWeight: 500
    },
    button: {
      backgroundColor: '#E0E0E0',
      borderRadius: 999,
      marginLeft: 20,
      padding: '8px 12px 8px'
    },
    buttonsContainer: {
      marginTop: 12,
      textAlign: 'right'
    },
    cancelButton: {
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-gray-1)',
      backgroundColor: 'var(--color-white)',
      marginRight: 16,
      '&:hover': {
        backgroundColor: '#eee'
      }
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)'
    }
  })
);

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  onClickNewData: (e: any) => void;
  onClickModifyData: (e: any) => void;
  targetDate: string;
  setTargetDate: (e: any) => void;
  isError: boolean;
  setIsError: (e: any) => void;
  processing: boolean;
  setProcessing: (value: boolean) => void;
  targetService: ServicerType;
}

const CsvExportModal: React.FC<Props> = props => {
  const {
    isOpen,
    setIsOpen,
    onClickNewData,
    onClickModifyData,
    targetDate,
    setTargetDate,
    isError,
    setIsError,
    processing,
    setProcessing,
    targetService
  } = props;

  const styles = useStyles({});
  const [minDate, setMinDate] = React.useState<string>('');
  const [maxDate, setMaxDate] = React.useState<string>('');

  const onSelectDate = React.useCallback(
    (date: MaterialUiPickersDate) => {
      setIsError(false);
      setTargetDate(moment(date ? date : '').format('YYYY-MM-DD'));
    },
    [setIsError, setTargetDate]
  );

  // CSVの再作成処理
  const recreateCsv = useCallback(async () => {
    // 対象日が当日の場合のみ処理を実施する
    if (moment().format('YYYY-MM-DD') !== targetDate) {
      return;
    }

    const projectServicerId = getProjectAndServicerIdFromType(
      getProjectType(defaultProjectId),
      targetService
    );

    const options: any = {
      method: 'GET',
      uri: `${ifBasePath}application/csv/upload/${projectServicerId}/${targetDate}`,
      timeout: 30 * 1000, // タイムアウト指定しないと帰ってこない場合がある
      headers: {
        'x-api-key':
          Redux.getStore().user.accessToken ||
          LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)
      },
      json: {
        refresh_token:
          Redux.getStore().user.refreshToken ||
          LocalStorage.get(LocalStorageKey.REFRESH_TOKEN)
      }
    };
    const result = await req(options).catch(e => false);
    // if (!result) {
    //   setIsError(true);
    // }
  }, [setIsError, targetDate, targetService]);

  React.useEffect(() => {
    const today = moment();
    setMaxDate(today.format('YYYY-MM-DD'));
    setMinDate(today.add(-30, 'days').format('YYYY-MM-DD'));
  }, []);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      className={styles.modal}
      onClose={() => setIsOpen(false)}
    >
      <div className={styles.container}>
        <IconButton
          className={styles.closeButton}
          onClick={() => setIsOpen(false)}
        >
          <Close />
        </IconButton>
        <h2 className={styles.heading}>CSVエクスポート</h2>
        <div className={styles.root}>
          <List component="nav">
            <ListItem divider={true}>
              <div className={styles.targetPeriodContainer}>
                <p className={styles.targetPeriodLabel}>対象期間</p>

                <MuiPickersUtilsProvider locale={jaLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    color="primary"
                    invalidDateMessage={'有効な日付を入力してください。'}
                    id="date-picker-dialog"
                    label=""
                    format="yyyy/MM/dd"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={targetDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    cancelLabel="キャンセル"
                    onChange={onSelectDate}
                    disabled={processing}
                    disableToolbar
                  />
                </MuiPickersUtilsProvider>
              </div>
            </ListItem>
            <ListItem className={styles.listItem} divider={true}>
              <ListItemText>新規登録データ</ListItemText>
              <IconButton
                edge="end"
                aria-label="download"
                onClick={async e => {
                  setProcessing(true);
                  await recreateCsv();
                  onClickNewData(e);
                }}
                disabled={isError || processing}
              >
                <GetApp />
              </IconButton>
            </ListItem>
            <ListItem className={styles.listItem} divider={true}>
              <ListItemText>取消・変更・解約・退去データ</ListItemText>
              <IconButton
                edge="end"
                aria-label="download"
                onClick={async e => {
                  setProcessing(true);
                  await recreateCsv();
                  onClickModifyData(e);
                }}
                disabled={isError || processing}
              >
                <GetApp />
              </IconButton>
            </ListItem>
          </List>
          <AlertMessage
            open={isError}
            level={'アラート'}
            title={'エクスポートできません。'}
            message={'対象期間のCSVデータは作成されていません。'}
          />
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.cancelButton}
              onClick={() => setIsOpen(false)}
            >
              キャンセル
            </Button>
          </div>
        </div>
        {processing && (
          <div className={styles.progress}>
            <CircularProgress />
          </div>
        )}
      </div>
    </Modal>
  );
};

const useAlertStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 'calc(100% - 32px)',
      height: 50,
      backgroundColor: 'var(--color-error)',
      color: 'var(--color-white)',
      padding: 16,
      margin: '10px 0',
      fontSize: 14
    },
    container: {
      position: 'relative',
      top: -16,
      left: -16
    },
    icon: {
      position: 'absolute',
      top: 16,
      left: 16
    },
    title: {
      position: 'absolute',
      top: 16,
      left: 52
    },
    message: {
      position: 'absolute',
      top: 45,
      left: 52
    }
  })
);

interface P {
  open: boolean;
  level: string;
  title: string;
  message: string;
}

const AlertMessage: React.FC<P> = props => {
  const {
    open,
    //level,
    title,
    message
  } = props;

  const styles = useAlertStyles();

  return (
    <>
      {open && (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.icon}>
              <Warning />
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.message}>{message}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CsvExportModal;
